import Image, { ImageProps } from 'next/image'
import { useMemo } from 'react'

import { getImageAttributes } from 'common/utils/content'

interface AssetStoryblok {
  _uid?: string
  id: number
  alt?: string
  name: string
  focus?: string
  source?: string
  title?: string
  filename: string
  copyright?: string
  fieldtype?: string
  meta_data?: null | {
    [k: string]: unknown
  }
  is_external_url?: boolean
  [k: string]: unknown
}

type StoryblokImageProps = Omit<ImageProps, 'src' | 'alt'> & {
  asset: AssetStoryblok
}

export const StoryblokImage = ({ asset, ...rest }: StoryblokImageProps) => {
  const attrs = useMemo(() => getImageAttributes(asset), [asset])

  if (!attrs.src) {
    return null
  }

  return <Image {...attrs} {...rest} alt={attrs.alt || ''} />
}
