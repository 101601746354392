import NextImage from 'next/image'
import styled from 'styled-components'

import { Container, Icon, Link, Tag, Text } from 'common/UI'
import { ClinicServicesStoryblok } from 'common/types'
import { getImageAttributes, textByLine } from 'common/utils/content'
import { addAlphaToColor } from 'common/utils/style'
import { CMSLink } from 'modules/shared'

import { Editable } from './Editable'

type Props = {
  block: ClinicServicesStoryblok
}

export const ClinicServices = ({ block, ...props }: Props): JSX.Element => {
  const entry = block.service

  return (
    <Container {...props}>
      <Wrapper>
        <InnerWrapper>
          <div>
            {block.tag && <Tag>{block.tag}</Tag>}
            {block.title && (
              <Text variant="title/large" as="h3" css={{ marginTop: '1rem' }}>
                {block.title}
              </Text>
            )}
          </div>
          <div>
            {block.description && (
              <WrapperDescription>
                {textByLine(block.description, (part) => (
                  <Text as="p" variant="twenty">
                    {part}
                  </Text>
                ))}
              </WrapperDescription>
            )}
            {block.link && block.link[0].label && block.link[0].link && (
              <HideMobile>
                <CMSLink href={block.link[0].link} variant="ghost">
                  {block.link[0].label}
                </CMSLink>
              </HideMobile>
            )}
          </div>
        </InnerWrapper>

        {entry.map((item, i) => {
          if (i !== 0) return null
          return (
            <Editable block={item} key={item._uid}>
              <CardWrapper href={item.link[0].link.url}>
                {item.image?.filename && (
                  <WrapperImage>
                    <NextImage
                      fill
                      priority
                      src={getImageAttributes(item.image).src}
                      alt={getImageAttributes(item.image).alt}
                      style={{ objectFit: 'cover', height: '100%' }}
                    />
                  </WrapperImage>
                )}
                <div css={{ padding: '1rem' }}>
                  <Text as="p" variant="twenty">
                    {item.title}
                  </Text>
                  <StyledDescription as="p" variant="eighteen">
                    {item.description}
                  </StyledDescription>
                </div>
              </CardWrapper>
            </Editable>
          )
        })}
        <WrapperSerices>
          {entry.map((item, i) => {
            if (i === 0) return null
            return (
              <Editable block={item} key={item._uid}>
                <CardWrapper href={item.link[0].link.url}>
                  <div
                    css={{
                      padding: '1rem',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                      alignItems: 'flex-start',
                    }}
                  >
                    <div>
                      <Text as="p" variant="twenty">
                        {item.title}
                      </Text>
                      <StyledDescription as="p" variant="eighteen">
                        {item.description}
                      </StyledDescription>
                    </div>
                    <HideMobile>
                      <Text as="p" variant="eighteen">
                        {item.link[0].label}
                      </Text>
                      <Icon icon="arrow-right" className="ml-1" />
                    </HideMobile>
                  </div>
                </CardWrapper>
              </Editable>
            )
          })}
        </WrapperSerices>
        {block.link && block.link[0].label && block.link[0].link && (
          <HideDesktop css={{ marginTop: '1.25rem' }}>
            <CMSLink href={block.link[0].link} variant="ghost">
              {block.link[0].label}
            </CMSLink>
          </HideDesktop>
        )}
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2.5rem 0rem;

  ${({ theme }) => theme.media.lg} {
    padding: 3.75rem 0rem;
    flex-direction: row;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ theme }) => theme.media.lg} {
    flex-direction: column;
    padding-right: 1.25rem;
    max-width: 25.8125rem;
  }
`

const WrapperDescription = styled.div`
  margin-bottom: 2rem;
  margin-top: 1.25rem;
  ${({ theme }) => theme.media.lg} {
    margin-top: 0rem;
    margin-bottom: 1.25rem;
  }
`

const CardWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.75rem;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.background.dark};
  overflow: hidden;

  ${({ theme }) => theme.media.lg} {
    max-width: 25.875rem;
  }
`

const WrapperImage = styled.div`
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: block;
    position: relative;
    width: 100%;
    height: 18.4375rem;
    overflow: hidden;
  }
`

const WrapperSerices = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.lg} {
    margin-left: 1.25rem;
  }
`

const HideMobile = styled.div`
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: flex;
    align-items: center;
  }
`

const HideDesktop = styled.div`
  display: block;

  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`

export const StyledDescription = styled(Text)`
  color: ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 80)};
`
