import { useState } from 'react'
import styled, { useTheme } from 'styled-components'

import { Icon, Link, Text, Tooltip } from 'common/UI'
import { GraphStoryblok, SuccessRateDynamicStoryblok } from 'common/types'
import { addAlphaToColor, dashedLine } from 'common/utils/style'
import { CMSLink } from 'modules/shared'

import { Editable } from '../Editable'

type Props = {
  graph: GraphStoryblok[]
}

const DESKTOP_HEIGHT = 309
const MOBILE_HEIGHT = 220

const renderColumns = (
  maxTfpSuccess: number,
  tfpSuccess: number,
  percentage: number
) => {
  const getHeight = (maxTfpSuccess: number, percentage: number) =>
    Number(((MOBILE_HEIGHT / maxTfpSuccess) * percentage).toFixed(2))

  const columnHeight = getHeight(maxTfpSuccess, tfpSuccess)
  const columnInnerHeight = getHeight(maxTfpSuccess, percentage)

  const renderColumn = (
    height: number,
    innerHeight: number,
    swapColor = false,
    textColor = ''
  ) => (
    <Column height={height} swapColor={swapColor}>
      <InnerColumn height={innerHeight} swapColor={swapColor} />
      <InnerPercentage>
        <Text as="p" variant="sixteen" color={textColor}>
          {percentage}
        </Text>
        <Text as="span" variant="twelve" color={textColor}>
          %
        </Text>
      </InnerPercentage>
    </Column>
  )

  if (columnHeight === 0) {
    return renderColumn(columnInnerHeight, columnInnerHeight)
  }

  if (columnHeight < columnInnerHeight) {
    return renderColumn(columnInnerHeight, columnHeight, true, 'palette.white')
  }

  return renderColumn(columnHeight, columnInnerHeight)
}

export const Graph = ({ graph }: Props) => {
  const theme = useTheme()

  const [isOpen, setIsOpen] = useState(false)
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )

  return (
    <>
      {graph.map((g, i) => {
        const maxTFP = Math.max(
          ...g.column.map((c) => Number(c.tfp_success_rate))
        )

        return (
          <WrapperGraph key={i} variant={g.variant}>
            <WrapperGraphLeft>
              <div>
                {g.description_graph && (
                  <Text as="p" variant="twenty">
                    {g.description_graph}
                  </Text>
                )}

                <WrapperSubtitle>
                  {g.subtitle1_graph && (
                    <WrapperSubtitleInner>
                      <Square color={theme.colors.palette.blue[200]} />
                      <Text
                        as="p"
                        variant="twenty"
                        css={{ marginLeft: '.5rem', marginRight: '1.25rem' }}
                      >
                        {g.subtitle1_graph}
                      </Text>
                    </WrapperSubtitleInner>
                  )}

                  {g.subtitle2_graph && (
                    <WrapperSubtitleInner>
                      <Square color={theme.colors.accent.secondary} />
                      <Text as="p" variant="twenty" css={{ marginLeft: '8px' }}>
                        {g.subtitle2_graph}
                      </Text>
                    </WrapperSubtitleInner>
                  )}
                </WrapperSubtitle>
              </div>

              <DesktopOnly>
                <WrapperInfoDesktop>
                  {g.primary_button_link && g.primary_button_text && (
                    <CMSLink
                      href={g.primary_button_link}
                      variant="ghost"
                      rightIcon="arrow-right"
                      css={{ marginBottom: '1.25rem' }}
                    >
                      {g.primary_button_text}
                    </CMSLink>
                  )}

                  {g.date_graph && (
                    <Text
                      as="p"
                      variant="sixteen"
                      css={{ marginBottom: '8px' }}
                    >
                      {g.date_graph}
                    </Text>
                  )}

                  {g.source_graph && (
                    <div css={{ display: 'flex' }}>
                      <Text
                        as="p"
                        variant="sixteen"
                        css={{ marginBottom: '8px' }}
                      >
                        {g.source_graph}
                      </Text>

                      {g.source_tooltip_graph && (
                        <div
                          ref={setReferenceElement}
                          onMouseEnter={() => setIsOpen(true)}
                          onMouseLeave={() => setIsOpen(false)}
                        >
                          <Icon icon="info" className="ml-1" />
                        </div>
                      )}

                      {g.source_tooltip_graph && (
                        <Tooltip
                          referenceElement={referenceElement}
                          isOpen={isOpen}
                          label={g.source_tooltip_graph}
                          css={{ maxWidth: '16.125rem', textAlign: 'center' }}
                        />
                      )}
                    </div>
                  )}

                  <LinksWrapper>
                    {g.links &&
                      g.links.map((l, i) => {
                        const isLast = g?.links?.length
                          ? i === g?.links?.length - 1
                          : false

                        return (
                          <LinkWrapper key={l._uid}>
                            {l.link && l.label && (
                              <Link
                                isUnderlined
                                href={l.link.url}
                                target="_blank"
                              >
                                {l.label}
                              </Link>
                            )}
                            {!isLast && <Dot />}
                          </LinkWrapper>
                        )
                      })}
                  </LinksWrapper>
                </WrapperInfoDesktop>
              </DesktopOnly>
            </WrapperGraphLeft>

            <WrapperColumns variant={g.variant}>
              {g.column.map((column, i) => {
                const pregnanciesEmbryos =
                  column.tfp_pregnancies_embryo?.split(/(?<=\/)/) || []
                return (
                  <Editable block={column} key={i}>
                    <WrapperColumn>
                      <WrapperPercentage>
                        <div>
                          <div className="md:hidden">
                            {pregnanciesEmbryos.map((text, i) => (
                              <Text
                                key={i}
                                as="p"
                                variant="fourteen"
                                color="accent.secondary"
                                css={{ width: '100%' }}
                              >
                                {text}
                              </Text>
                            ))}
                          </div>
                          <div className="hidden md:block">
                            <Text
                              as="p"
                              variant="fourteen"
                              color="accent.secondary"
                            >
                              {column.tfp_pregnancies_embryo}
                            </Text>
                          </div>
                        </div>
                        <div
                          css={{
                            display: 'flex',
                            alignItems: 'baseline',
                            textWrap: 'wrap',
                          }}
                        >
                          <Text as="p" variant="title/card">
                            {column.tfp_success_rate}
                          </Text>
                          <Text as="p" variant="twenty/regular">
                            %
                          </Text>
                        </div>
                      </WrapperPercentage>

                      {renderColumns(
                        maxTFP,
                        Number(column.tfp_success_rate),
                        Number(column.nation_average)
                      )}

                      <Text as="p" variant="sixteen">
                        {column.age_gap}
                      </Text>
                    </WrapperColumn>
                  </Editable>
                )
              })}
            </WrapperColumns>

            <TabletOnly>
              <WrapperInfoDesktop>
                {g.primary_button_link && g.primary_button_text && (
                  <CMSLink
                    href={g.primary_button_link}
                    variant="ghost"
                    rightIcon="arrow-right"
                    css={{ marginBottom: '1.25rem' }}
                  >
                    {g.primary_button_text}
                  </CMSLink>
                )}

                {g.date_graph && (
                  <Text as="p" variant="sixteen" css={{ marginBottom: '8px' }}>
                    {g.date_graph}
                  </Text>
                )}

                {g.source_graph && (
                  <div css={{ display: 'flex' }}>
                    <Text
                      as="p"
                      variant="sixteen"
                      css={{ marginBottom: '8px' }}
                    >
                      {g.source_graph}
                    </Text>

                    {g.source_tooltip_graph && (
                      <div
                        ref={setReferenceElement}
                        onMouseEnter={() => setIsOpen(true)}
                        onMouseLeave={() => setIsOpen(false)}
                      >
                        <Icon icon="info" className="ml-1" />
                      </div>
                    )}

                    {g.source_tooltip_graph && (
                      <Tooltip
                        referenceElement={referenceElement}
                        isOpen={isOpen}
                        label={g.source_tooltip_graph}
                        css={{ maxWidth: '16.125rem', textAlign: 'center' }}
                      />
                    )}
                  </div>
                )}

                <LinksWrapper>
                  {g.links &&
                    g.links.map((l, i) => {
                      const isLast = g?.links?.length
                        ? i === g?.links?.length - 1
                        : false

                      return (
                        <LinkWrapper key={l._uid}>
                          {l.link && l.label && (
                            <Link
                              isUnderlined
                              href={l.link.url}
                              target="_blank"
                            >
                              {l.label}
                            </Link>
                          )}
                          {!isLast && <Dot />}
                        </LinkWrapper>
                      )
                    })}
                </LinksWrapper>
              </WrapperInfoDesktop>
            </TabletOnly>

            <WrapperInfoMobile>
              {g.primary_button_link && g.primary_button_text && (
                <CMSLink
                  href={g.primary_button_link}
                  variant="ghost"
                  rightIcon="arrow-right"
                  css={{ marginBottom: '1.25rem' }}
                >
                  {g.primary_button_text}
                </CMSLink>
              )}

              {g.date_graph && (
                <Text as="p" variant="fourteen" css={{ marginBottom: '8px' }}>
                  {g.date_graph}
                </Text>
              )}

              {g.source_graph && (
                <Text as="p" variant="fourteen" css={{ marginBottom: '8px' }}>
                  {g.source_graph}. {g.source_tooltip_graph}
                </Text>
              )}

              <LinksWrapper>
                {g.links &&
                  g.links.map((l, i) => {
                    const isLast = g?.links?.length
                      ? i === g?.links?.length - 1
                      : false

                    return (
                      <LinkWrapper key={l._uid}>
                        {l.link && l.label && (
                          <Link isUnderlined href={l.link.url} target="_blank">
                            <Text as="p" variant="fourteen">
                              {l.label}
                            </Text>
                          </Link>
                        )}
                        {!isLast && <Dot />}
                      </LinkWrapper>
                    )
                  })}
              </LinksWrapper>
            </WrapperInfoMobile>
          </WrapperGraph>
        )
      })}
    </>
  )
}

const WrapperGraph = styled.div<{
  variant: SuccessRateDynamicStoryblok['variant']
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 2rem;

  gap: 2.5rem;

  overflow: hidden;

  ${({ theme }) =>
    dashedLine('top', addAlphaToColor(theme.colors.foreground.default, 60))};

  ${({ theme }) => theme.media.md} {
    gap: 2.5rem;
  }

  ${({ theme }) => theme.media.lg} {
    gap: 6.875rem;
    flex-direction: ${({ variant }) =>
      variant === 'left' ? 'row-reverse' : 'row'};
    padding-top: 2.5rem;
  }
`

const WrapperGraphLeft = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.md} {
    max-width: 34.375rem;

    justify-content: space-between;
  }
`

const Square = styled.div<{ color: string }>`
  width: 1.25rem;
  height: 1.25rem;
  background-color: ${({ color }) => color};
  border-radius: 0.375rem;
`

const WrapperSubtitle = styled.div`
  display: flex;
  margin-top: 1.25rem;
  flex-direction: column;
`

const WrapperSubtitleInner = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  ${({ theme }) => theme.media.md} {
    margin-bottom: 0.25rem;
  }
`

const LinksWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 0.1rem;
`

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Dot = styled.div`
  display: flex;
  width: 0.125rem;
  height: 0.125rem;
  background: ${({ theme }) => theme.colors.foreground.default};
  margin: 0px 0.5rem;
`

const WrapperInfoDesktop = styled.div`
  display: none;

  ${({ theme }) => theme.media.md} {
    display: block;
  }
`

const WrapperInfoMobile = styled.div`
  display: block;

  ${({ theme }) => theme.media.md} {
    display: none;
  }
`

const WrapperColumns = styled.div<{
  variant: SuccessRateDynamicStoryblok['variant']
}>`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 0.5rem;
  align-items: end;

  ${({ theme }) => theme.media.md} {
    gap: 0.75rem;
    display: flex;
  }

  ${({ theme }) => theme.media.lg} {
    justify-content: ${({ variant }) => (variant === 'left' ? 'start' : 'end')};
  }
`

const WrapperColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;

  ${({ theme }) => theme.media.lg} {
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
`
const WrapperPercentage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.accent.secondary};
`

const Column = styled.div<{ height: number; swapColor?: boolean }>`
  position: relative;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  border-radius: 0.75rem;
  background: ${({ theme, swapColor }) =>
    swapColor ? theme.colors.palette.blue[200] : theme.colors.accent.secondary};
  overflow: hidden;

  ${({ theme }) => theme.media.md} {
    width: 93.33px;
    border-radius: 1.25rem;
    height: ${({ height }) => `${(height * DESKTOP_HEIGHT) / MOBILE_HEIGHT}px`};
  }
`
const InnerColumn = styled.div<{ height: number; swapColor?: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${({ height }) => `${height}px`};
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  background: ${({ theme, swapColor }) =>
    swapColor ? theme.colors.accent.secondary : theme.colors.palette.blue[200]};

  ${({ theme }) => theme.media.md} {
    width: 93.337px;
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    height: ${({ height }) => `${(height * DESKTOP_HEIGHT) / MOBILE_HEIGHT}px`};
  }
`

const InnerPercentage = styled.div`
  display: flex;
  align-items: baseline;
  position: absolute;
  bottom: 0.125rem;
  left: 50%;
  transform: translate(-50%, 0);

  ${({ theme }) => theme.media.md} {
    bottom: 0.5rem;
  }
`

const DesktopOnly = styled.div`
  display: none;

  ${({ theme }) => theme.media.lg} {
    display: block;
  }
`

const TabletOnly = styled.div`
  display: none;

  ${({ theme }) => theme.media.md} {
    display: block;
  }

  ${({ theme }) => theme.media.lg} {
    display: none;
  }
`
