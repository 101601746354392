import NextImage from 'next/image'
import styled from 'styled-components'

import { Container, Icon, Tag, Text } from 'common/UI'
import { ClinicTestimonialStoryblok } from 'common/types'
import { getImageAttributes, textByLine } from 'common/utils/content'
import { addAlphaToColor, dashedLine } from 'common/utils/style'
import { CMSLink } from 'modules/shared'

import { Graph } from './Graph/Graph'

type Props = {
  block: ClinicTestimonialStoryblok
}

export const ClinicTestimonial = ({ block, ...props }: Props): JSX.Element => {
  const entry = block.entry

  return (
    <Container {...props}>
      <OutterWrapper>
        <Wrapper>
          <InnerWrapper dashed>
            <div>
              {block.tag && <Tag>{block.tag}</Tag>}
              {block.title && (
                <Text variant="title/large" as="h3" css={{ marginTop: '1rem' }}>
                  {block.title}
                </Text>
              )}
            </div>
            <div>
              {block.description && (
                <WrapperDescription>
                  {textByLine(block.description, (part) => (
                    <Text as="p" variant="twenty">
                      {part}
                    </Text>
                  ))}
                </WrapperDescription>
              )}
              {block.link && block.link[0].label && block.link[0].link && (
                <CMSLink href={block.link[0].link} variant="ghost">
                  {block.link[0].label}
                </CMSLink>
              )}
            </div>
          </InnerWrapper>
          <InnerWrapper>
            {entry && (
              <>
                {entry[0].image.filename && (
                  <WrapperImage>
                    <NextImage
                      fill
                      priority
                      src={getImageAttributes(entry[0].image).src}
                      alt={getImageAttributes(entry[0]).alt}
                      style={{ objectFit: 'cover', height: '100%' }}
                    />
                  </WrapperImage>
                )}
                <div
                  css={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  {entry[0].description && (
                    <Text
                      as="p"
                      variant="title/medium"
                      css={{ marginBottom: '1.25rem' }}
                    >
                      " <br /> {entry[0].description}
                    </Text>
                  )}
                  <div>
                    {entry[0].name && (
                      <Text as="p" variant="eighteen/regular">
                        {entry[0].name}
                      </Text>
                    )}
                    {entry[0].treatment && (
                      <div
                        css={{
                          display: 'flex',
                          gap: '.375rem',
                          marginTop: '.75rem',
                        }}
                      >
                        <Icon icon="treatments" className="opacity-60" />
                        <Text
                          as="p"
                          variant="eighteen"
                          css={{ opacity: '80%' }}
                        >
                          {entry[0].treatment}
                        </Text>
                      </div>
                    )}
                    {entry[0].clinic && (
                      <div
                        css={{
                          display: 'flex',
                          gap: '.375rem',
                        }}
                      >
                        <Icon icon="house" className="opacity-60" />
                        <Text
                          as="p"
                          variant="eighteen"
                          css={{ opacity: '80%' }}
                        >
                          {entry[0].clinic}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </InnerWrapper>
        </Wrapper>
        {block.graph && <Graph graph={block.graph} />}
      </OutterWrapper>
    </Container>
  )
}

const WrapperImage = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 15rem;
  border-top-right-radius: 3rem;
  border-bottom-left-radius: 3rem;
  overflow: hidden;
  flex-shrink: 0;
  margin: 2rem 0rem 1.25rem 0rem;

  ${({ theme }) => theme.media.lg} {
    width: 22.125rem;
    height: 23.75rem;
    margin: 0rem 2.5rem;
  }
`

const OutterWrapper = styled.div`
  padding: 2.5rem 0rem;

  ${({ theme }) => theme.media.lg} {
    padding: 2.5rem 0rem 3.75rem 0rem;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;

  ${({ theme }) => theme.media.lg} {
    padding: 2.5rem 0rem;
    flex-direction: row;
    ${({ theme }) =>
      dashedLine('top', addAlphaToColor(theme.colors.foreground.default, 60))};
  }
`

const InnerWrapper = styled.div<{ dashed?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ theme }) => theme.media.lg} {
    flex-direction: ${({ dashed }) => (dashed ? 'column' : 'row')};
    padding-right: ${({ dashed }) => (dashed ? '2.5rem' : '0rem')};
    max-width: ${({ dashed }) => (dashed ? '24.5rem' : 'unset')};

    ${({ theme, dashed }) =>
      dashed &&
      dashedLine('right', addAlphaToColor(theme.colors.foreground.default, 60))}
  }
`

const WrapperDescription = styled.div`
  margin-bottom: 1rem;

  ${({ theme }) => theme.media.lg} {
    margin-bottom: 1.25rem;
  }
`
