import NextImage from 'next/image'
import NextLink from 'next/link'
import { ISbStoryData } from 'storyblok-js-client'

import { Button } from 'common/UI'
import {
  BlogPostStoryblok,
  PatientStoryStoryblok,
  ServiceStoryblok,
} from 'common/types'
import {
  getImageAttributes,
  sortServicesTypesAlphabetically,
} from 'common/utils/content'
import { formatDateLocale } from 'common/utils/datetime'
import { removeTrailingSlash } from 'common/utils/string'
import { cn } from 'common/utils/tailwind'
import { useTranslation } from 'lib/i18n'

import { TagList } from '../TagList'

type Props = {
  item: ISbStoryData<BlogPostStoryblok> | ISbStoryData<PatientStoryStoryblok>
  variant: 'blog' | 'story'
}

export const getThumbCategories = (content: unknown): string[] => {
  if (Array.isArray(content)) {
    return content.map(
      (cat: { content: { type: string } }) => cat.content?.type
    )
  }
  if (typeof content === 'string' && content.length > 0) {
    return [content]
  }

  if (typeof content === 'object' && content && 'content' in content) {
    return [(content as { content: { type: string } }).content.type]
  }

  return []
}

export const BigThumb = ({ item, ...props }: Props): JSX.Element => {
  const { locale, i18n } = useTranslation()

  const serviceColors = (type: ServiceStoryblok['type']) => {
    switch (type) {
      case 'diagnosis':
        return {
          bg: 'bg-background-secondary-diagnosis-light',
        }
      case 'treatment':
        return {
          bg: 'bg-background-secondary-treatments-light',
        }
      case 'donation':
        return {
          bg: 'bg-background-secondary-donation-light',
        }
      case 'lgbtqia+':
        return {
          bg: 'bg-background-secondary-lgbtqia-light',
        }
      case 'preservation':
        return {
          bg: 'bg-background-secondary-preservation-light',
        }
      case 'condition':
        return {
          bg: 'bg-background-secondary-condition-light',
        }
      case 'advice':
        return {
          bg: 'bg-background-secondary-advice-light',
        }
      case 'news':
        return {
          bg: 'bg-background-secondary-news-light',
        }

      default:
        return {
          bg: 'bg-background-secondary-news-light',
        }
    }
  }

  const categories = Array.from(
    new Set(
      item.content.component === 'patient-story'
        ? getThumbCategories(item.content.categories)
        : getThumbCategories(item.content.services)
    )
  ) as Array<ServiceStoryblok['type']>

  const orderedCategories = sortServicesTypesAlphabetically(categories)
  const serviceColor = serviceColors(orderedCategories[0])

  return (
    <NextLink
      {...props}
      href={removeTrailingSlash(`/${item.full_slug}`)}
      className={cn(
        'flex overflow-hidden flex-col-reverse justify-between text-foreground rounded-4 md:flex-row md:h-[465px] gap-5 md:gap-16',
        serviceColor.bg
      )}
    >
      <div className="flex flex-col justify-between pt-0 p-3 md:p-8 md:pr-0 w-full">
        <div className="flex flex-col gap-3 md:gap-5">
          <div className="flex items-center gap-3">
            {orderedCategories?.length > 0 && (
              <TagList
                hasHiddenTags
                hasColorVariants
                maxVisibleTags={2}
                tags={orderedCategories}
              />
            )}

            <p className="text-sixteen">
              {item.first_published_at
                ? formatDateLocale(item.first_published_at, locale)
                : ''}
            </p>
          </div>

          <h2 className="text-title-small md:text-title font-regular">
            {item.content.title}
          </h2>
        </div>

        <Button
          as="a"
          variant="outline"
          icon="arrow-right"
          iconPosition="right"
          className="hidden md:flex"
          href={removeTrailingSlash(`/${item.full_slug}`)}
        >
          {i18n('readStory')}
        </Button>
      </div>

      {item.content.cover_image && item.content.cover_image.filename && (
        <div className="relative h-[319px] md:h-[449px] md:w-full rounded-4 overflow-hidden mb-0 m-2 md:mb-2 md:ml-0 aspect-auto">
          <NextImage
            fill
            sizes="700"
            src={
              getImageAttributes(
                item.content.cover_image,
                '800x400/smart/filters:format(webp)'
              ).src
            }
            className="object-cover w-full h-full"
            alt={getImageAttributes(item.content.cover_image).alt}
          />
        </div>
      )}
    </NextLink>
  )
}
