import { useRouter } from 'next/router'
import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import { ISbStoryData } from 'storyblok-js-client'
import styled from 'styled-components'

import { Container, Text } from 'common/UI'
import useDrawer from 'common/hooks/useDrawer'
import { BlogPostStoryblok, LatestEntriesStoryblok } from 'common/types'
import { getStartsWith, getStoryblokCacheValue } from 'common/utils/content'
import { useDataContext } from 'lib/dataContext'
import { Storyblok } from 'lib/storyblok'
import { ContentThumb, CMSLink } from 'modules/shared'
import { CardLoader } from 'modules/shared/CardLoader'

type Props = {
  block: LatestEntriesStoryblok
}

export const LatestBlogPosts = ({
  block,
  ...props
}: Props): JSX.Element | null => {
  const {
    isPreview,
    query: { lang },
  } = useRouter()

  const { drawer } = useDrawer()
  const isDrawer = !!drawer

  const {
    title,
    button_label,
    button_link,
    starts_with,
    item_amount = '3',
  } = block

  const bySlugs = (starts_with || '')
    .split(';')
    .map((s) => getStartsWith(lang as string, s.trim()) + '*')
    .join(',')

  const { storyId } = useDataContext()
  const [data, setData] = useState<ISbStoryData<BlogPostStoryblok>[] | null>(
    null
  )
  const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle')

  const fetch = useCallback(async () => {
    setStatus('loading')

    try {
      const {
        data: { stories },
      } = await Storyblok.get('cdn/stories', {
        version: isPreview ? 'draft' : 'published',
        content_type: 'blog-post',
        page: 1,
        per_page: isDrawer ? 2 : Number(item_amount),
        sort_by: 'first_published_at:desc',
        by_slugs: bySlugs,
        resolve_relations: 'blog-post.services',
        excluding_ids: storyId?.toString(),
        cv: getStoryblokCacheValue(isPreview),
      })

      setStatus('idle')
      setData(stories)
    } catch (error) {
      setStatus('error')
    }
  }, [bySlugs, isPreview, item_amount, storyId])

  useEffect(() => {
    fetch()
  }, [fetch])

  const itemsToShow = isDrawer ? 2 : +item_amount

  const ContentWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
    return <Grid itemAmount={itemsToShow}>{children}</Grid>
  }

  if (status === 'error') return null

  if (status === 'idle' && data?.length === 0) return null

  return (
    <Wrapper isDrawer={isDrawer} {...props}>
      <Header>
        {title && (
          <Text as="h2" variant="title/large">
            {title}
          </Text>
        )}

        {button_label && button_link && (
          <CMSLink rightIcon="arrow-right" variant="ghost" href={button_link}>
            {button_label}
          </CMSLink>
        )}
      </Header>

      {status === 'loading' ? (
        <ContentWrapper>
          {[...new Array(+item_amount)].map((_, index) => (
            <CardLoader key={index} />
          ))}
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          {data?.slice(0, itemsToShow).map((entry) => (
            <ContentThumb key={entry.id} item={entry} />
          ))}
        </ContentWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(Container)<{ isDrawer: boolean }>`
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;

  padding-left: 1.25rem;
  padding-right: 1.25rem;

  ${({ theme }) => theme.media.lg} {
    padding-left: ${({ isDrawer }) => (isDrawer ? '2rem' : '5rem')};
    padding-right: ${({ isDrawer }) => (isDrawer ? '2rem' : '5rem')};
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;

  align-items: start;
  justify-content: space-between;
  gap: 1rem;

  margin-bottom: 2.5rem;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
    align-items: flex-start;
  }
`

const Grid = styled.div<{ itemAmount: number }>`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.25rem;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: repeat(${({ itemAmount }) => itemAmount}, 1fr);
  }
`
