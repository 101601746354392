import { AnimatePresence, motion } from 'framer-motion'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import ContentLoader from 'react-content-loader'
import { ISbStoryData } from 'storyblok-js-client'
import styled, { useTheme } from 'styled-components'

import {
  OldButton,
  Container,
  Icon,
  Portal,
  Tag,
  Text,
  TextField,
  Theme,
} from 'common/UI'
import { useBodyLock } from 'common/hooks/useBodyLock'
import useDebounce from 'common/hooks/useDebounce'
import {
  BlogPostStoryblok,
  ClinicStoryblok,
  PageStoryblok,
  PatientStoryStoryblok,
} from 'common/types'
import { getStoryblokCacheValue } from 'common/utils/content'
import { formatDateLocale } from 'common/utils/datetime'
import { searchContent } from 'common/utils/search'
import { findAndSplit, removeTrailingSlash } from 'common/utils/string'
import { addAlphaToColor, dashedLine } from 'common/utils/style'
import { useTranslation } from 'lib/i18n'
import { Storyblok } from 'lib/storyblok'

type DataPerType = {
  page: ISbStoryData<PageStoryblok>[]
  blog: ISbStoryData<BlogPostStoryblok>[]
  patient: ISbStoryData<PatientStoryStoryblok>[]
  clinic: ISbStoryData<ClinicStoryblok>[]
}

type Props = {
  isVisible: boolean
  onClose: () => void
}

const formatResultsToArray = (searchTerm: string, string: string) => {
  return findAndSplit(searchTerm, string)?.map((item) => {
    if (item.toLowerCase() === searchTerm.toLowerCase()) {
      return <BoldText>{item}</BoldText>
    }
    return item
  })
}

export const SearchPanel = ({ isVisible, onClose }: Props): JSX.Element => {
  useBodyLock(isVisible)

  const router = useRouter()
  const {
    query: { lang },
  } = router
  const { isPreview } = useRouter()
  const { i18n, locale } = useTranslation()
  const [input, setInput] = useState<HTMLInputElement | null>(null)

  const [data, setData] = useState<ISbStoryData[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const search = useCallback(
    async (s: string) => {
      setLoading(true)
      try {
        const {
          data: { stories },
        } = await Storyblok.get('cdn/stories', {
          version: isPreview ? 'draft' : 'published',
          starts_with: `${lang}/`,
          search_term: s,
          filter_query: {
            component: {
              in: ['page', 'blog-post', 'patient-story', 'clinic'].join(','),
            },
          },
          cv: getStoryblokCacheValue(isPreview),
        })

        setData(stories)
      } catch (error) {
        // noop
      } finally {
        setLoading(false)
      }
    },
    [isPreview, lang]
  )

  const handleClose = useCallback(() => {
    onClose()
    setData([])
    setSearchTerm('')
  }, [onClose])

  useEffect(
    function focusOnMount() {
      if (isVisible && input) {
        input.focus()
      }
    },
    [isVisible, input]
  )

  useEffect(
    function doSearch() {
      if (debouncedSearchTerm) {
        search(debouncedSearchTerm)
      } else {
        setData([])
      }
    },
    [debouncedSearchTerm, search]
  )

  useEffect(
    function closeOnRouteChange() {
      const handleRouteChange = () => {
        handleClose()
      }

      router.events.on('routeChangeStart', handleRouteChange)

      return () => {
        router.events.off('routeChangeStart', handleRouteChange)
      }
    },
    [router.events, handleClose]
  )

  const dataPerType = useMemo(() => {
    return data.reduce<DataPerType>(
      (acc, story) => {
        switch (story.content.component) {
          case 'page':
            return {
              ...acc,
              page: [...acc.page, story as ISbStoryData<PageStoryblok>],
            }
          case 'blog-post':
            return {
              ...acc,
              blog: [...acc.blog, story as ISbStoryData<BlogPostStoryblok>],
            }
          case 'patient-story':
            return {
              ...acc,
              patient: [
                ...acc.patient,
                story as ISbStoryData<PatientStoryStoryblok>,
              ],
            }
          case 'clinic':
            return {
              ...acc,
              clinic: [...acc.clinic, story as ISbStoryData<ClinicStoryblok>],
            }
          default:
            // fail silently
            return acc
        }
      },
      { page: [], blog: [], patient: [], clinic: [] }
    )
  }, [data])

  return (
    <AnimatePresence>
      {isVisible && (
        <Portal>
          <Theme colorTheme="light">
            <Overlay
              onMouseDown={handleClose}
              onTouchStart={handleClose}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { delay: 0.1 } }}
              transition={{ ease: 'easeOut', duration: 0.1 }}
            >
              <Holder
                onMouseDown={(e) => e.stopPropagation()}
                onTouchStart={(e) => e.stopPropagation()}
                initial={{ y: '-100%' }}
                animate={{ y: 0 }}
                exit={{ y: '-100%' }}
                transition={{ type: 'spring', damping: 30, stiffness: 300 }}
              >
                <Container>
                  <Header>
                    <div
                      css={{
                        position: 'relative',
                        flex: 1,
                        marginRight: '1rem',
                      }}
                    >
                      <TextField
                        variant="input"
                        ref={(node) => setInput(node as HTMLInputElement)}
                        style={{ paddingLeft: '3rem' }}
                        name="search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.currentTarget.value)}
                        placeholder={i18n('search.searchWebsite')}
                      />
                      <Icon
                        icon="search"
                        size={20}
                        className="absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none"
                      />
                    </div>
                    <OldButton
                      css={{
                        width: '2.5rem',
                        height: '2.5rem',
                      }}
                      variant="ghost"
                      leftIcon="close"
                      size="small"
                      onClick={handleClose}
                    />
                  </Header>
                  {!loading && !debouncedSearchTerm && (
                    <ImageHolder>
                      <img src="/assets/search-empty.svg" alt="" />
                    </ImageHolder>
                  )}
                  {loading && data.length === 0 && (
                    <ResultsContainer>
                      <LoadingColumn amount={2} />
                      <LoadingColumn amount={3} />
                      <LoadingColumn amount={1} />
                    </ResultsContainer>
                  )}
                  {!loading && debouncedSearchTerm && data.length === 0 && (
                    <ImageHolder>
                      <img src="/assets/search-no-results.svg" alt="" />
                      <Text
                        as="p"
                        variant="twenty"
                        css={{ paddingTop: '2rem' }}
                      >
                        {i18n('noResults')}
                      </Text>
                    </ImageHolder>
                  )}
                  {debouncedSearchTerm && data.length > 0 && (
                    <ResultsContainer
                      style={
                        loading ? { opacity: 0.5, pointerEvents: 'none' } : {}
                      }
                    >
                      {dataPerType.clinic.length > 0 && (
                        <Column>
                          {dataPerType.clinic.map((item) => {
                            return (
                              <Thumb
                                key={item.id}
                                title={item.content?.name || item.name}
                                link={
                                  item.content?.homepage?.cached_url
                                    ? `/${item.content.homepage.cached_url}`
                                    : '/'
                                }
                                image={item.content?.image?.filename}
                                description={item.content.location}
                              />
                            )
                          })}
                        </Column>
                      )}
                      {dataPerType.blog.length > 0 && (
                        <Column>
                          {dataPerType.blog.map((item) => {
                            return (
                              <Thumb
                                key={item.id}
                                title={item.content?.title || item.name}
                                link={removeTrailingSlash(
                                  debouncedSearchTerm
                                    ? `/${item.full_slug}?search=${debouncedSearchTerm}`
                                    : `/${item.full_slug}`
                                )}
                                image={item.content?.cover_image?.filename}
                                description={formatDateLocale(
                                  item.first_published_at || '',
                                  locale
                                )}
                              />
                            )
                          })}
                        </Column>
                      )}
                      {dataPerType.patient.length > 0 && (
                        <Column>
                          <Tag className="mb-4">
                            {i18n('search.patientStories')}
                          </Tag>
                          {dataPerType.patient.map((item, index) => {
                            const search = searchContent(
                              dataPerType.patient,
                              debouncedSearchTerm
                            )

                            const resultsArray = formatResultsToArray(
                              debouncedSearchTerm,
                              search[index]?.cutText
                            )

                            return (
                              <Thumb
                                key={item.id}
                                title={item.content?.title || item.name}
                                link={removeTrailingSlash(
                                  debouncedSearchTerm
                                    ? `/${item.full_slug}?search=${debouncedSearchTerm}`
                                    : `/${item.full_slug}`
                                )}
                                image={item.content?.cover_image?.filename}
                                description={
                                  resultsArray || item.content?.patient_names
                                }
                              />
                            )
                          })}
                        </Column>
                      )}
                      {dataPerType.page.length > 0 && (
                        <Column>
                          {dataPerType.page.map((item, index) => {
                            const search = searchContent(
                              dataPerType.page,
                              debouncedSearchTerm
                            )

                            const resultsArray = formatResultsToArray(
                              debouncedSearchTerm,
                              search[index]?.cutText
                            )

                            return (
                              <Thumb
                                key={item.id}
                                title={item.content?.seo?.title || item.name}
                                link={removeTrailingSlash(
                                  debouncedSearchTerm
                                    ? `/${item.full_slug}?search=${debouncedSearchTerm}`
                                    : `/${item.full_slug}`
                                )}
                                description={
                                  resultsArray || item.content?.seo?.description
                                }
                              />
                            )
                          })}
                        </Column>
                      )}
                    </ResultsContainer>
                  )}
                </Container>
              </Holder>
            </Overlay>
          </Theme>
        </Portal>
      )}
    </AnimatePresence>
  )
}

const LoadingColumn = ({ amount = 3 }: { amount: number }) => {
  const theme = useTheme()

  return (
    <Column>
      <ContentLoader
        backgroundColor={addAlphaToColor(theme.colors.foreground.subtle, 40)}
        foregroundColor={addAlphaToColor(theme.colors.foreground.subtle, 10)}
        opacity={0.25}
        viewBox={`0 0 100 ${amount * 10}`}
      >
        {new Array(amount).fill('').map((_, i) => (
          <rect
            key={i}
            x="0"
            y={i * 10}
            rx="2"
            ry="2"
            width={Math.floor(Math.random() * (100 - 80 + 1) + 60)}
            height="6"
          />
        ))}
      </ContentLoader>
    </Column>
  )
}

export const Thumb = ({
  image,
  title,
  description,
  link,
}: {
  image?: string
  title: string
  description?: (string | ReactElement)[] | string
  link: string
}) => {
  return (
    <NextLink href={link} passHref style={{ width: '100%' }}>
      <ThumbContent>
        {image && <ThumbImage style={{ backgroundImage: `url(${image})` }} />}
        <ThumbTextContent>
          <TruncatedText variant="eighteen" as="p" color="foreground.default">
            {title}
          </TruncatedText>
          {description && (
            <TruncatedText variant="sixteen" as="p" color="foreground.subtle">
              {description}
            </TruncatedText>
          )}
        </ThumbTextContent>
      </ThumbContent>
    </NextLink>
  )
}

const Header = styled.div`
  padding: 1rem 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 99999;

  background-color: rgba(0, 0, 0, 0.3);

  overflow-y: auto;
`

const Holder = styled(motion.div)`
  cursor: default;

  background-color: ${({ theme }) => theme.colors.background.default};

  // pseudo-element to allow the panel to spring
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    display: block;
    height: 20vh;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background.default};
  }
`

const ImageHolder = styled.div`
  padding: 3.75rem;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;
`

const ResultsContainer = styled.div`
  padding-bottom: 2rem;

  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
`

const Column = styled.div`
  ${({ theme }) =>
    dashedLine('top', addAlphaToColor(theme.colors.foreground.default, 20))};
  padding-top: 1rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ theme }) => theme.media.md} {
    :last-of-type {
      grid-column: span 3;
    }
  }
`

const ThumbContent = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  margin-bottom: 1rem;

  color: inherit;
  transition: opacity ${({ theme }) => theme.transitions.ease('150ms')};

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
`

const ThumbTextContent = styled.div`
  width: calc(100% - 3.75rem); // thumb image + thumb image margin
`

const TruncatedText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const BoldText = styled.b`
  font-weight: 600;
`

const ThumbImage = styled.div`
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  margin-right: 0.75rem;

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
`
