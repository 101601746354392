import { ISbStoryData } from 'storyblok-js-client'

import { StoryblokImage } from 'common/components/StoryblokImage'
import useDrawer from 'common/hooks/useDrawer'
import { TeamMemberDetailPageStoryblok } from 'common/types'
import { textByLine } from 'common/utils/content'
import { cn } from 'common/utils/tailwind'
import { storyblokEditable } from 'lib/storyblok/storyblokEditable'
import { TagList } from 'modules/shared/TagList'

type Props = {
  story: ISbStoryData<TeamMemberDetailPageStoryblok>
}

export const TeamMemberDetailPageHeader = ({ story }: Props): JSX.Element => {
  const { drawer } = useDrawer()
  const isDrawer = !!drawer

  const specialties = story.content.specialties
    ? story.content.specialties?.map((specialty) => specialty.toString())
    : []

  return (
    <div className="bg-background" {...storyblokEditable(story.content)}>
      <div
        className={cn(
          'container mx-auto flex flex-col gap-4 px-5 md:px-0 pt-5 pb-8 md:flex-row md:justify-between',
          !isDrawer && 'max-w-rich-text'
        )}
      >
        <div className="flex flex-col justify-between gap-5 md:gap-18">
          <div className="h-full space-y-5">
            {story.name && (
              <h1 className="text-title-large font-regular">{story.name}</h1>
            )}

            {story.content.role_detail && (
              <div className="space-y-2.5">
                {textByLine(story.content.role_detail, (part) => (
                  <p className="text-foreground/80 text-sixteen">{part}</p>
                ))}
              </div>
            )}
          </div>

          {specialties.length ? (
            <div className="space-y-2">
              {/* TODO: add this to the strings datasources */}
              {story.content.speciality_title && (
                <p className="text-foreground/60 text-eighteen font-regular">
                  {story.content.speciality_title}
                </p>
              )}

              <div className="flex flex-wrap gap-2 w-full">
                <TagList
                  tags={specialties}
                  maxVisibleTags={specialties.length}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        {story.content.image?.filename && (
          <div className="flex w-full overflow-hidden rounded-4 min-w-[273px]">
            <StoryblokImage
              asset={story.content.image}
              className="object-cover"
            />
          </div>
        )}
      </div>
    </div>
  )
}
